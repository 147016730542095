<template>
	<div v-if="visible">
		<!-- sub-step 0 ricerca telaio -->
		<stepper-container v-if="substep==0" :loading="loading" :errors="sstep0errors" title="Dati telaio" subtitle="Inserire il numero di telaio o di targa da cercare" @forward="cercaTelaioTarga">
			<v-text-field v-model="codiceTelaioTarga" label="Numero di telaio o della targa" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" required></v-text-field>
			<v-alert v-if="visualizzaAvvisoCreazioneTelaio" type="warning">
				Non è stato trovato un telaio corrispondente nel database.<p>Vuoi crearlo? </p>
				<v-btn x-small color="primary" @click="creazioneTelaio">crea telaio</v-btn>
			</v-alert>
		</stepper-container>

		<!-- sub-step 50 nuovo telaio -->
		<stepper-container v-if="substep==50" :loading="loading" :errors="sstep50errors" :title="nuovoTelaio ? 'Nuovo telaio' : 'Dati telaio'" :subtitle="nuovoTelaio ? 'Inserire i dati del nuovo telaio' : 'Verifica e completa i dati del telaio'" :show-previous-button="true" button-previous-text="Torna alla ricerca telaio" @forward="salvaTelaio" @previous="tornaAllaRicerca">
			<v-row>
				<v-col>
					<v-text-field v-model="telaio.CodTelaio" :disabled="!nuovoTelaio" label="Numero di telaio" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" required @change="caricaListaModelli"></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-text-field v-model="telaio.Targa" :disabled="!nuovoTelaio" label="Numero di targa" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" required></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col xl="9" lg="8" md="7" sm="6">
					<v-row>
						<v-col>
							<v-select v-model="telaio.Modello" label="Modello" :items="listaModelli" item-text="Modello" item-value="Modello" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense"></v-select>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-select v-model="telaio.Gpl" :items="gplList" label="Gpl" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense"></v-select>
						</v-col>
					</v-row>
				</v-col>
				<v-col xl="3" lg="4" md="5" sm="6" class="text-right">
					<v-date-picker v-model="telaio.DataImmatricolazione"></v-date-picker>
					<div class="subheading">Data di immatricolazione</div>
				</v-col>
			</v-row>			
			<v-alert v-if="telaio.Assegnato" class="mt-6" type="warning">
				Attenzione, il telaio è già assegnato a:

				<table class="ma-6 table-proprietario">
					<tr><td class="caption">Card</td><td class="subtitle-2">{{ telaio.CardAssegnata }}</td></tr>
					<tr><td class="caption">Nominativo</td><td class="subtitle-2">{{ telaio.Proprietario.Nome }} {{ telaio.Proprietario.Cognome }}</td></tr>
					<tr><td class="caption">Codice fiscale</td><td class="subtitle-2">{{ telaio.Proprietario.Nome }} {{ telaio.Proprietario.Codicefiscale }}</td></tr>
					<tr>
						<td class="caption">Indirizzo</td>
						<td class="subtitle-2">
							{{ telaio.Proprietario.Tipovia }} {{ telaio.Proprietario.Indirizzo }} {{ telaio.Proprietario.Civico }}<br>
							{{ telaio.Proprietario.Comune }} ({{ telaio.Proprietario.Provincia }})
						</td>
					</tr>
				</table>
				<strong>Procedendo verrà riassegnato!</strong>
			</v-alert>
		</stepper-container>

		
		
		<debug-panel label="telaio" class="mt-6">
			{{ telaio }}
		</debug-panel>	
	</div>
</template>

<script>
/**********************************************************************************************
**** STEP 3 - registrazione targa o telaio ****************************************************
***********************************************************************************************
* questo step è particolarmente complesso ed è in pratica formato da alcuni sub-step, per questo motivo è stato componentizzato a parte
*
* la prima parte prevede la ricerca del telaio per codice o per targa
* se non trovato ne viene consentito il caricamento da zero
* viene inoltre sollevato un avviso qualora il telaio sia già associato ad una diversa card, dando comunque la possibilità di effettuarne una ri-associazione
* todo: restano da gestire gli avvisi in caso di telaio gold associato ad una card normale e viceversa 
*/
// import moment from 'moment'
import DebugPanel from '@/components/debug-panel'
import StepperContainer from '@/components/stepper-container'

export default {
	name: 'CardNewStep3',
	components: { StepperContainer, DebugPanel },
	props: {
		// questo è il v-model, ed equivale al nostro oggetto card 
		value: {
			type: Object,
			required: true,
			default: () => {return {}}
		},
		// proprietà che indica quando questo step è effettivamente visibile 
		// è inoltre sotto watch per determinare il momento in cui diventa visibile e avviare quindi alcune operazioni
		visible: {
			type: Boolean,
			required: true,
			default: () => {return false}
		},
	},
	data: () => {
		return {
			substep: 0, // sub-step corrente
			loading: false, // loading globale
			// sub-step 0 - ricerca telaio per targa o codice
			codiceTelaioTarga: '', // codice telaio o targa cercato
			codiceIsTelaio: false, // flag di comodo da usare ad esempio negli errori per capire se l'utente ha inserito una targa o un codice telaio
			telaio: {}, // telaio risultante della ricerca (usato anche per la creazione di un nuovo telaio nel sub-step 50)
			sstep0errors: [], // errori vari 
			visualizzaAvvisoCreazioneTelaio: false, // nel caso il telaio non sia stato trovato questo flag forza la visualizzazione di un'avviso che propone la creazione di un nuovo telaio (sub-step 50)
			// sub-step 50 - creazione nuovo telaio
			sstep50errors: [], // errori vari 
			nuovoTelaio: false, // indica un telaio caricato da zero
			listaModelli: [], // lista modelli associata al  telaio
			gplList: [ 'Si', 'No' ]
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		setup() { return this.$store.getters['setup'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },	
		currentUser() { return this.$store.getters['currentUser'] },
	},
	watch:{
		// quando la proprietà visible passa a true significa che lo step è stato visualizzato
		visible(to, from) {
			if(to!==from && to) {
				if (this.appSetup.isDevelop && this.viewDebugElements) {
					this.codiceTelaioTarga = 'FM5FM52J025N0GZS5'
				}
			}
		}
	},		
	created: function () {
		//
	},	
	methods: {
		// substep 0 - cerca il numero di telaio o della targa del veicolo
		cercaTelaioTarga() {
			this.sstep0errors = []
			this.telaio = {}
			this.visualizzaAvvisoCreazioneTelaio = false
			this.nuovoTelaio = false
			if(this.codiceTelaioTarga.length==7 || this.codiceTelaioTarga.length==17) {
				this.codiceIsTelaio = this.codiceTelaioTarga.length==17
				this.loading = true
				this.$store.dispatch('genericApiPost', {apiUrl: '/card/cercaTargaTelaio', value: {Code: this.codiceTelaioTarga}}).then(result => {
					if(result) {
						// telaio trovato
						this.telaio = result
						this.caricaTelaio()
					} else {
						// telaio non trovato, non visualizziamo un errore ma un avviso nel quale si suggerisce di creare un nuovo telaio e se ok si passa allo step #50 
						this.visualizzaAvvisoCreazioneTelaio = true
						// this.sstep0errors.push({text: 'Il telaio non è stato trovato'})
					}
				}).catch(error => {
					this.sstep0errors.push({text: error, pre: true})
				}).finally(() => {
					this.loading = false
				})
			} else {
				this.sstep0errors.push({text: 'Il valore inserito non corrisponde ne ad una targa (7 caratteri) ne ad un codice telaio (17 caratteri)!'})
			}
		},
		// passa al sub-sep 50 e carica i dati di un telaio (blocca i campi del codice e della targa)
		caricaTelaio() {
			this.nuovoTelaio = false
			this.caricaListaModelli()
			this.substep=50
		},
		// passa al sub-sep 50 per la creazione di un nuovo telaio
		creazioneTelaio() {
			this.nuovoTelaio = true
			this.telaio = {
				CodTelaio: this.codiceIsTelaio ? this.codiceTelaioTarga : '',
				DataImmatricolazione: null,
				Registrato: false,
				Targa: this.codiceIsTelaio ? '' : this.codiceTelaioTarga,
				Modello: null,
				Gpl: null
			}
			this.caricaListaModelli()
			this.substep=50
		},
		// torna allo step 0 e ricomincia con la ricerca 
		tornaAllaRicerca() {
			this.sstep0errors = []
			this.telaio = {}
			this.visualizzaAvvisoCreazioneTelaio = false
			this.nuovoTelaio = false
			this.substep=0
		},
		caricaListaModelli() {
			let codiceTelaio = this.telaio ? this.telaio.CodTelaio: ''
			if(codiceTelaio && codiceTelaio.length==17) {
				this.loading = true
				this.listaModelli = []
				this.$store.dispatch('genericApiPost', {apiUrl: '/card/modelliPerTelaio', value: {Code: codiceTelaio}}).then(result => {
					this.listaModelli = result
					if (this.appSetup.isDevelop && this.viewDebugElements) {
						this.telaio.Modello= 'ALTRO MODELLO'
						this.telaio.Gpl= 'No'
					}
				}).catch(error => {
					this.sstep50errors.push({text: error, pre: true})
				}).finally(() => {
					this.loading = false
				})
			}
		},
		// salva i dati del telaio e passa allo step successivo
		salvaTelaio() {
			// verifica che ci siano tutti i dati necessari e poi procede
			let valid = true
			if(!this.telaio || !this.telaio.CodTelaio || !this.telaio.Targa || !this.telaio.DataImmatricolazione || !this.telaio.Modello || !this.telaio.Gpl) {
				valid = false
				this.sstep50errors.push({text: 'É necessario compilare tutti i campi'})
			}
			if(valid) {
				this.telaio.New = this.nuovoTelaio
				this.value.Telaio = this.telaio // aggiorna il valore in uscita
				// this.$emit('input',  this.cardData) // aggiorna il v-model che contiene i dati della card in corso di creazione
				this.$emit('forward') // scatena l'evento di forward finale (che  effettua quindi il passaggio allo step successivo)
			}
		}
	},
}
</script>

<style scoped lang="less">
	.table-proprietario {
		td {
			padding-left: 5px;
		}
	}
</style>