<template>
	<div>
		<v-card flat class="mb-6" :color="darkTheme ? 'grey darken-4' : 'grey lighten-4'" min-height="200px" :loading="loading">
			<!--
				interessante e potenzialmente utile ma, per ora, non funziona bene perché sposta in basso tutto il contenuto
			<template slot="progress">
				<v-progress-linear
					height="5"
					indeterminate
				></v-progress-linear>
			</template>
			-->
			<v-card-title>{{ title }}</v-card-title>
			<v-card-subtitle>{{ subtitle }}</v-card-subtitle>
			<v-card-text>
				<slot />
			</v-card-text>
		</v-card>

		<errors-container :errors="errors"></errors-container>
	

		<v-row>
			<v-col v-if="showPreviousButton"><v-btn outlined :disabled="cantForward" :loading="loading" @click="previous">{{ buttonPreviousText }}</v-btn></v-col>
			<v-col v-if="showForwardButton" class="text-right"><v-btn outlined color="primary" :disabled="cantForward" :loading="loading" @click="forward">{{ buttonForwardText }}</v-btn></v-col>
			<v-col v-if="showFinishButton" class="text-center"><v-btn outlined color="success" x-large :loading="loading" @click="finish">{{ buttonFinishText }}</v-btn></v-col>
		</v-row>
	</div>
</template>


<script>

// ***********************************************************************************
// ** componente "container" standard per gli stepper
// ** progettato inizialmente per l'aggiunta delle nuove card (quindi senza possibilità di back allo step precedente)
// ** il pulsante "procedi" viene attivato se canForward è a true (utile per disattiva se elementi fondamentali dello step non sono stati compilati)
// ** gli errori vengono visualizzati come lista se è compilato l'array errors
// ***********************************************************************************
// 
import ErrorsContainer from '@/components/errors-container'
export default {
	name: 'StepperContainerComponent',
	components: { ErrorsContainer },
	props: {
		// 
		title: {
			type: String,
			default: () => {return ''}
		},
		// sottotitolo della card
		subtitle: {
			type: String,
			default: () => {return ''}
		},
		// 
		loading: {
			type: Boolean,
			default: () => {return false}
		},
		// flag che disabilita il pulsante di avanzamento
		cantForward: {
			type: Boolean,
			default: () => {return false}
		},
		// lista errori da visualizzare
		errors: {
			type: Array,
			default: () => {return []}
		},
		buttonForwardText: {
			type: String,
			default: () => {return 'Procedi'}
		},
		// consente di nascondere il pulsante avanti (normalmente visibile)
		showForwardButton: {
			type: Boolean,
			default: () => {return true}
		},
		// visualizza il pulsante back (normalmente nascosto)
		showPreviousButton: {
			type: Boolean,
			default: () => {return false}
		},
		buttonPreviousText: {
			type: String,
			default: () => {return 'Indietro'}
		},
		// visulizza il grande pulsante centrale, pensato per il termine degli step (normalmente nascosto)
		showFinishButton: {
			type: Boolean,
			default: () => {return false}
		},
		buttonFinishText: {
			type: String,
			default: () => {return 'Fine'}
		},
	},
	computed: {
		appMenu() { return this.$store.getters['appMenu'] },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		darkTheme() { return this.$vuetify.theme.dark },
	},
	methods: {
		// procede allo step successivo
		forward() {
			this.$emit('forward')
		},
		// torna allo step precedente
		previous() {
			this.$emit('previous')
		},
		finish() {
			this.$emit('finish')
		},
	},
}
</script>

<style scoped lang="less">

</style>
