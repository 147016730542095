<template>
	<div>
		<guide-panel id="card.regolamento.regolamento" title="REGOLAMENTO SUPERCARD">
			Regolamenti e allegati.
		</guide-panel>
		<v-row>
			<v-col cols="12">
				<v-data-table
					:loading="loading"
					:items="files" 
					:headers="headers"
					:sort-by="['Nome']" 
					:sort-desc="[false]"
					item-key="Id"
					show-expand
					:expanded.sync="expanded"
					:single-expand="true"
					disable-pagination
					disable-sort
					hide-default-footer
				>
					<template v-slot:[`item.Caricamento`]="{ item }">
						{{ item.Caricamento | shortDate }}
					</template>
					<template v-slot:[`item.Id`]="{ item }">
						<v-btn x-small text @click.stop="viewFile(item)"><v-icon title="Apri documento" color="primary">mdi-magnify</v-icon> visualizza</v-btn>
					</template>
					<template v-slot:expanded-item="{ headers, item }">
						<td :colspan="headers.length">
							<div class="expanded">
								<p>{{ item.Descrizione }}</p>
							</div>
						</td>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		<errors-container :errors="errors"></errors-container>
	</div>
</template>

<script>
/**********************************************************************************************
**** CARDS - VISUALIZZA DOCUMENTI REGOLAMENTO *********************************************************
***********************************************************************************************
* 
*/
import GuidePanel from '@/components/guide-panel'
import ErrorsContainer from '@/components/errors-container'
export default {
	name: 'CardsRegolamento',
	components: { GuidePanel, ErrorsContainer  },
	data: () => {
		return {
			loading: false,
			files: [],
			errors: [],
			expanded: [],
			headers: [
				{ text: 'Nome', value: 'Nome'},
				{ text: 'Data caricamento', value: 'Caricamento'}, 
				{ text: '', value: 'Id', width:50}, // colonna fittizia view
			],
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		setup() { return this.$store.getters['setup'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },	
		currentUser() { return this.$store.getters['currentUser'] },
	},
	created: function () {
		if (this.appSetup.isDevelop) {
		}
		this.loadFiles()
	},
	methods: {
		// carica tutti i dati per l'area regolamento
		loadFiles(){
			this.loading = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/file/listRegolamentoSuperCard'}).then(result => {
				this.files = result
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loading = false
			})
		},
		// visualizza un singolo file
		viewFile(item) {
			this.loading = true
			this.$store.dispatch('openDocument', item.Id).then(() => {
				// nop
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loading = false
			})
		},
	},
}
</script>

<style scoped lang="less">

</style> 