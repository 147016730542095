<template>
	<div v-if="visible">
		<!-- recapiti -->
		<stepper-container :loading="loading" :errors="errors" title="Recapiti" subtitle="" @forward="salvaRecapiti">
			<v-form ref="form" v-model="validForm" lazy-validation>
				<v-row>
					<v-col>
						<v-subheader>Residenza</v-subheader>
						<v-divider></v-divider>
					</v-col>
				</v-row>
				<v-row>
					<v-col md="3"><v-select v-model="value.Cliente.Tipovia" :rules="rules.required" :items="decodifiche.TipoVie" label="Tipo via" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" required></v-select></v-col>
					<v-col md="6"><v-text-field v-model="value.Cliente.Indirizzo" :rules="rules.required" label="Indirizzo" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" required></v-text-field></v-col>
					<v-col md="3"><v-text-field v-model="value.Cliente.Civico" :rules="rules.required" label="Numero civico" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" required></v-text-field></v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-radio-group v-model="isEstero" row label="Luogo di residenza" @change="variazioneStatoResidenza">
							<v-radio :value="false" label="Italia"></v-radio> 
							<v-radio :value="true" label="Estero"></v-radio> 
						</v-radio-group>
					</v-col>
				</v-row>				
				<v-row v-if="isEstero">
					<v-col md="9">
						<v-autocomplete v-model="value.Cliente.Comune" label="Stato estero" :rules="rules.required" :items="listaStati" item-text="NomeStato" item-value="NomeStato" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense"></v-autocomplete>
					</v-col>
					<v-col md="3"><v-text-field v-model="value.Cliente.Cap" label="Cap" :rules="rules.required" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" required></v-text-field></v-col>
				</v-row>
				<v-row v-else>
					<v-col md="3">
						<v-autocomplete v-model="value.Cliente.Provincia" label="Provincia" :rules="rules.required" :items="listaProvincie" item-text="NomeProv" item-value="Sigla" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" @change="variazioneProvincia"></v-autocomplete>
					</v-col>
					<v-col md="6">
						<v-autocomplete v-model="value.Cliente.Comune" :rules="rules.required" :disabled="!value.Cliente.Provincia" label="Comune" :items="listaComuni" item-text="NomeComune" item-value="NomeComune" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense"></v-autocomplete>
					</v-col>
					<v-col md="3"><v-text-field v-model="value.Cliente.Cap" :rules="rules.required" label="Cap" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" required></v-text-field></v-col>
				</v-row>
			</v-form>
			<v-row>
				<v-col>
					<v-subheader>Contatti</v-subheader>
					<v-divider></v-divider>
				</v-col>
			</v-row>
			<v-row>
				<v-col md="6"><v-text-field v-model="value.Cliente.TelFisso" label="Telefono fisso" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense"></v-text-field></v-col>
				<v-col md="6"><v-text-field v-model="value.Cliente.TelMobile" label="Telefono cellulare" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense"></v-text-field></v-col>
				<v-col md="12"><v-text-field v-model="value.Cliente.Email" :rules="rules.email" label="Email" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense"></v-text-field></v-col>
			</v-row>
			<v-row>
				<v-col md="6">
					<v-radio-group v-model="value.Cliente.Smartphone" row label="Possiedi uno smartphone?">
						<v-radio value="SI" label="Si"></v-radio> 
						<v-radio value="NO" label="No"></v-radio> 
					</v-radio-group>
				</v-col>
				<v-col md="6">
					<v-select v-if="value.Cliente.Smartphone == 'SI'" v-model="value.Cliente.PhoneOs" :items="decodifiche.PhoneOs" label="Tipologia di smartphone posseduto" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense"></v-select>
				</v-col>
			</v-row>
		</stepper-container>

		
		<v-row>
			<v-col>
				<debug-panel label="value.Cliente" class="mt-6">
					{{ value.Cliente }}
				</debug-panel>	
			</v-col>
		</v-row>
	</div>
</template>

<script>
/**********************************************************************************************
**** STEP 5 - recapiti ****************************************************
***********************************************************************************************
* questo step è particolarmente complesso, per questo motivo è stato componentizzato a parte
*
* caricamento recapiti
* 
* 
* 
*/
import moment from 'moment'
import DebugPanel from '@/components/debug-panel'
import StepperContainer from '@/components/stepper-container'
//
export default {
	name: 'CardNewStep5',
	components: { StepperContainer, DebugPanel },
	props: {
		// questo è il v-model, ed equivale al solito oggetto card 
		value: {
			type: Object,
			required: true,
			default: () => {return {}}
		},
		// proprietà che indica quando questo step è effettivamente visibile 
		// è inoltre sotto watch per determinare il momento in cui diventa visibile e avviare quindi alcune operazioni
		visible: {
			type: Boolean,
			required: true,
			default: () => {return false}
		},
	},
	data: () => {
		return {
			loading: false, // loading globale
			validForm: true, 
			rules: {
				required: [
					v => !!v || 'Campo obbligatorio',
				],
				email: [
					v => /.+@.+\..+/.test(v) || 'E-mail non valida',
				],
			},
			listaProvincie: [],
			listaComuni: [],
			listaStati: [],
			errors: [], // errori vari 
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		setup() { return this.$store.getters['setup'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },	
		currentUser() { return this.$store.getters['currentUser'] },
		decodifiche() { return this.$store.getters['decodifiche'] },
		// se la provincia corrente è impostata in EE allora il cliente è nato all'estero
		isEstero: {
			get: function() {
				return this.value.Cliente.Provincia == 'EE'
			},
			set: function(newValue) {
				this.value.Cliente.Provincia = newValue ? 'EE' : ''
				this.value.Cliente.Comune = ''
			}
		}		
	},
	watch:{
		// quando la proprietà visible passa a true significa che lo step è stato visualizzato
		visible(to, from) {
			if(to!==from && to) {
				this.caricaProvince() 
				this.caricaCliente().then(() => {
					if (this.appSetup.isDevelop && this.viewDebugElements) {
						// non compila con i dati di test se trova dati pre-caricati da caricaCliente 
						if(this.value.Cliente.Indirizzo == '') {
							this.value.Cliente.Tipovia = 'VIA'
							this.value.Cliente.Indirizzo = 'dei Paperi'
							this.value.Cliente.Civico = '333'
							this.value.Cliente.Provincia = 'RM'
							this.value.Cliente.Comune = 'ROMA'
							this.value.Cliente.Cap = '12345'
							this.caricaComuni()
						}
					}				
				})
			}
		}
	},	
	mounted: function () {
		//
	},	
	methods: {
		// se esiste un cliente con questo codice fiscale ne carica i dati 
		caricaCliente() {
			return new Promise((resolve) => {
				this.loading = true
				this.$store.dispatch('genericApiPost', {apiUrl: '/card/getCliente', value: {CodiceFiscale: this.value.Cliente.Codicefiscale}}).then(result => {
					if(result) {
						console.warn('sovrascrittura cliente:', result)
						this.value.Cliente.Tipovia = result.Tipovia
						this.value.Cliente.Indirizzo = result.Indirizzo
						this.value.Cliente.Civico = result.Civico
						this.value.Cliente.Provincia = result.Provincia
						this.value.Cliente.Comune = result.Comune
						this.value.Cliente.Cap = result.Cap
						this.value.Cliente.TelFisso = result.TelFisso
						this.value.Cliente.TelMobile = result.TelMobile
						this.value.Cliente.Email = result.Email
						this.value.Cliente.Smartphone = result.Smartphone
						this.value.Cliente.PhoneOs = result.PhoneOs
						this.caricaComuni()
					}
				}).catch(error => {
					this.errors.push({text: error, pre: true})
				}).finally(() => {
					this.loading = false
					resolve()
				})
			})
		},
		// carica tutte le province (rispetto allo step precedente qui carica la lista delle attuali)
		caricaProvince() {
			this.loading = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/card/listaProvinceAttuali'}).then(result => {
				this.listaProvincie = result
				if(!result)
					this.errors.push({text: 'Errore: non è stato possibile scaricare l\'elenco delle provincie (attuali)'})
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loading = false
			})
		},
		// carica tutti i comuni della provincia selezionata (usa la data corrente come filtro)
		caricaComuni() {
			if(this.value.Cliente.Provincia) {
				this.loading = true
				this.$store.dispatch('genericApiPost', {apiUrl: '/card/listaComuni', value: {SiglaProvincia: this.value.Cliente.Provincia, DataFiltro: moment().format('YYYY-MM-DD')}}).then(result => {
					this.listaComuni = result
				}).catch(error => {
					this.errors.push({text: error, pre: true})
				}).finally(() => {
					this.loading = false
				})
			}
		},		
		// carica tutti gli stati (usa la data corrente come filtro)
		caricaStati() {
			this.loading = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/card/listaStati', value: {DataFiltro: moment().format('YYYY-MM-DD')}}).then(result => {
				this.listaStati = result
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loading = false
			})
		},		
		// chiamato dalla variazione del radio-button dello stato di residenza, scatena il reload degli stati o dei comuni
		variazioneStatoResidenza() {
			if(this.isEstero) 
				this.caricaStati()
			else 
				this.caricaComuni()
		},
		// alla variazione della provincia ricarica i relativi comuni
		variazioneProvincia() {
			this.caricaComuni()
		},		
		// salva i recapiti e passa allo step successivo
		salvaRecapiti() {
			this.errors = []
			// verifica che ci siano tutti i dati necessari e poi procede
			if(this.$refs.form.validate()) {
				this.$emit('forward') // scatena l'evento di forward finale (che  effettua quindi il passaggio allo step successivo)
			} else {
				this.errors.push({text: 'É necessario compilare tutti i campi obbligatori'})
			}
		},
	},
}
</script>

<style scoped lang="less">
</style>