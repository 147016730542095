<template>
	<div v-if="visible">
		<!-- eslint-disable vue/no-v-html -->
		<!-- recapiti -->
		<stepper-container :loading="loading" :errors="errors" title="Riepilogo" subtitle="" button-forward-text="CONFERMA" @forward="salvaCard">
			<v-row>
				<v-col>
					<div class="overline">Supercard</div>
					<v-divider></v-divider>
				</v-col>
			</v-row>
			<v-row>
				<v-col md="6"><readonly-text :value="value.Numero" label="ID-Card"></readonly-text></v-col>
				<v-col md="6"><readonly-text :value="value.DataRegistrazione" showdate label="Data attivazione"></readonly-text></v-col>
			</v-row>
			<v-row>
				<v-col>
					<div class="overline">Telaio</div>
					<v-divider></v-divider>
				</v-col>
			</v-row>
			<v-row>
				<v-col md="4"><readonly-text :value="value.Telaio.CodTelaio" label="Codice"></readonly-text></v-col>
				<v-col md="4"><readonly-text :value="value.Telaio.Targa" label="Targa"></readonly-text></v-col>
				<v-col md="4"><readonly-text :value="value.Telaio.DataImmatricolazione" showdate label="Data immatricolazione"></readonly-text></v-col>
				<v-col md="4"><readonly-text :value="value.Telaio.Modello" label="Modello"></readonly-text></v-col>
				<v-col md="4"><readonly-text :value="value.Telaio.Gpl" label="Gpl"></readonly-text></v-col>
			</v-row>
			<div v-if="value.Cliente.Sesso == 'D'">
				<v-row>
					<v-col>
						<div class="overline">Dati azienda</div>
						<v-divider></v-divider> 
					</v-col>
				</v-row>
				<v-row>
					<v-col md="6"><readonly-text :value="value.Cliente.RagSoc" label="Ragione sociale"></readonly-text></v-col>
					<v-col md="6"><readonly-text :value="value.Cliente.Codicefiscale" label="Partita iva"></readonly-text></v-col>
				</v-row>
			</div>
			<div v-else>
				<v-row>
					<v-col>
						<div class="overline">Anagrafica</div>
						<v-divider></v-divider>
					</v-col>
				</v-row>
				<v-row>
					<v-col md="4"><readonly-text :value="value.Cliente.Nome" label="Nome"></readonly-text></v-col>
					<v-col md="4"><readonly-text :value="value.Cliente.Cognome" label="Cognome"></readonly-text></v-col>
					<v-col md="4"><readonly-text :value="value.Cliente.Sesso" label="Sesso"></readonly-text></v-col>
					<v-col md="4"><readonly-text :value="value.Cliente.DataNascita" showdate label="Data di nascita"></readonly-text></v-col>
					<v-col md="4"><readonly-text :value="value.Cliente.ProvinciaNas" label="Provincia di nascita"></readonly-text></v-col>
					<v-col md="4"><readonly-text :value="value.Cliente.ComuneNas" label="Comune di nascita"></readonly-text></v-col>
					<v-col md="4"><readonly-text :value="value.Cliente.Codicefiscale" label="Codice Fiscale"></readonly-text></v-col>
				</v-row>				
			</div>
			<v-row>
				<v-col>
					<div class="overline">Recapiti</div>
					<v-divider></v-divider>
				</v-col>
			</v-row>	
			<v-row>
				<v-col md="8"><readonly-text :value="value.Cliente.Tipovia + ' ' + value.Cliente.Indirizzo + ' ' + value.Cliente.Civico" label="Indirizzo"></readonly-text></v-col>
				<v-col md="4"><readonly-text :value="value.Cliente.Cap" label="Cap"></readonly-text></v-col>
				<v-col md="8"><readonly-text :value="value.Cliente.Comune" label="Comune"></readonly-text></v-col>
				<v-col md="4"><readonly-text :value="value.Cliente.Provincia" label="Provincia"></readonly-text></v-col>
				<v-col md="4"><readonly-text :value="value.Cliente.TelFisso" label="Telefono fisso"></readonly-text></v-col>
				<v-col md="4"><readonly-text :value="value.Cliente.TelMobile" label="Telefono mobile"></readonly-text></v-col>
				<v-col md="4"><readonly-text :value="value.Cliente.Email" label="Indirizzo e-mail"></readonly-text></v-col>
				<v-col md="12"></v-col>
				<v-col md="4"><readonly-text :value="value.Cliente.Smartphone" label="Ha uno smartphone"></readonly-text></v-col>
				<v-col md="4"><readonly-text :value="value.Cliente.PhoneOs" label="Piattaforma"></readonly-text></v-col>
			</v-row>		
			<v-form ref="form" v-model="validForm" lazy-validation>
				<v-row>
					<v-col>
						<div class="overline">Privacy</div>
						<v-divider></v-divider>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<div style="height:200px; overflow:auto" v-html="decodifiche.TestoPrivacyNewCard"></div>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<div class="overline">AUTORIZZAZIONE AL TRATTAMENTO DEI DATI</div>
						<v-divider></v-divider>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="6"><v-checkbox v-model="value.Cliente.Privacy" label="CONSENSO PRIVACY AL SERVIZIO" :rules="rules.required" value="S" class="ma-0"></v-checkbox></v-col>
					<v-col cols="6">
						<small v-html="decodifiche.TestoPrivacyNewCardChbServizio"></small>
					</v-col>

					<v-col cols="6">
						<v-checkbox v-model="value.Cliente.Privacy2" label="CONSENSO PRIVACY MARKETING" value="S" class="ma-0"></v-checkbox>
					</v-col>
					<v-col cols="6">
						<small v-html="decodifiche.TestoPrivacyNewCardChbMarketing"></small>
					</v-col>
					<v-col cols="6">
						<v-checkbox v-model="value.Cliente.Privacy3" label="CONSENSO PRIVACY PROFILAZIONE" value="S" class="ma-0"></v-checkbox>
					</v-col>
					<v-col cols="6">
						<small v-html="decodifiche.TestoPrivacyNewCardChbProfilazione"></small>
					</v-col>
				</v-row>				
				<v-row>
					<v-col><div v-html="decodifiche.TestoPrivacyNewCard2"></div></v-col>
				</v-row>
			</v-form>
		</stepper-container>

		
		<v-row>
			<v-col>
				<debug-panel label="value" class="mt-6">
					{{ value }}
				</debug-panel>	
			</v-col>
		</v-row>
	</div>
</template>

<script>
/**********************************************************************************************
**** STEP 6 - riepilogo e privacy ****************************************************
***********************************************************************************************
* questo è l'ultimo step nel quale viene proposto un riepilogo e vengono richiesti i permessi di privacy
*
* 
*/
// import moment from 'moment'
import DebugPanel from '@/components/debug-panel'
import StepperContainer from '@/components/stepper-container'
import ReadonlyText from '@/components/readonly-text'
//
export default {
	name: 'RipilogoStep',
	components: { StepperContainer, DebugPanel, ReadonlyText },
	props: {
		// questo è il v-model, ed equivale al solito oggetto card 
		value: {
			type: Object,
			required: true,
			default: () => {return {}}
		},
		// proprietà che indica quando questo step è effettivamente visibile 
		// è inoltre sotto watch per determinare il momento in cui diventa visibile e avviare quindi alcune operazioni
		visible: {
			type: Boolean,
			required: true,
			default: () => {return false}
		},
	},
	data: () => {
		return {
			loading: false, // loading globale
			validForm: true, 
			rules: {
				required: [
					v => !!v || 'Campo obbligatorio',
				],
			},

			errors: [], // errori vari 
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		setup() { return this.$store.getters['setup'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },	
		currentUser() { return this.$store.getters['currentUser'] },
		decodifiche() { return this.$store.getters['decodifiche'] },
	},
	watch:{
		// quando la proprietà visible passa a true significa che lo step è stato visualizzato
		visible(to, from) {
			if(to!==from && to) {
				if (this.appSetup.isDevelop && this.viewDebugElements) {

				}				
			}
		}
	},	
	mounted: function () {
		//
	},	
	methods: {
		// FINE STEP - SALVA TUTTO
		salvaCard() {
			this.errors = []
			// verifica che ci siano tutti i dati necessari e poi procede
			if(this.$refs.form.validate()) {

				// invia tutti i dati al server e speriamo bene! :-D
				this.loading = true
				this.$store.dispatch('genericApiPost', {apiUrl: '/card/saveCard', value: this.value}).then(result => {

					if(result.Ok) {
						this.$emit('forward') // scatena l'evento di forward finale (che  effettua quindi il passaggio allo step successivo)
					} else {
						this.errors.push({text: 'errore'})	
					}

				}).catch(error => {
					this.errors.push({text: error, pre: true})
				}).finally(() => {
					this.loading = false
				})				

				
			} else {
				this.errors.push({text: 'É necessario compilare tutti i campi obbligatori'})
			}
		},
	},
}
</script>

<style scoped lang="less">
</style>