<template>
	<div>
		<v-alert v-if="showErrors" type="error" outlined text>
			<div class="title">Errori</div>
			<v-list dense color="transparent">
				<template v-for="(item, idx) in errors">
					<v-list-item :key="idx">
						<!--
						<v-list-item-icon>
							<v-icon>mdi-alert-circle</v-icon>
						</v-list-item-icon>
						-->
						<v-list-item-content>
							<v-list-item-title v-if="item.pre"><pre style="max-height:400px;overflow:auto">{{ item.text }}</pre></v-list-item-title>
							<!-- eslint-disable-next-line vue/no-v-html -->
							<v-list-item-title v-else-if="item.html" v-html="item.text"></v-list-item-title>
							<v-list-item-title v-else v-text="item.text"></v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-divider v-if="errors.length>1" :key="idx+'d'"></v-divider>
				</template>
			</v-list>
		</v-alert>
		<!-- vecchia versione grafica
		<v-card v-if="showErrors" flat class="mb-6" color="error">
			<v-card-title>Errori</v-card-title>
			<v-card-text>
				<v-list dense color="error">
					<template v-for="(item, idx) in errors">
						<v-list-item :key="idx">
							<v-list-item-icon>
								<v-icon>mdi-alert-circle</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title v-if="item.pre"><pre style="max-height:400px;overflow:auto">{{ item.text }}</pre></v-list-item-title>
								<v-list-item-title v-else-if="item.html" v-html="item.text"></v-list-item-title>
								<v-list-item-title v-else v-text="item.text"></v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-divider :key="idx+'d'"></v-divider>
					</template>
				</v-list>
			</v-card-text>
		</v-card>
		-->
	</div>
</template>

<script>
/**********************************************************************************************
**** Componente per la visualizzazione standardizza degli errori ******************************
***********************************************************************************************
* accetta in input  una lista di errori "errors" [{text:'errore1'}, {text:'errore2'}]
* ogni elemento deve possedere come minimo il campo text
* ulteriori campi possibili sono
*	pre: visualizza l'errore in un tag pre
*	html: carica l'errore come html
* 
*/
//
export default {
	name: 'DummyInternalPage',
	components: { },
	props: {
		// 
		errors: {
			type: Array,
			required: true,
			default: () => {return []}
		},
	},
	data: () => {
		return {
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		setup() { return this.$store.getters['setup'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },	
		showErrors() {
			return this.errors.length > 0
		}
	},
	created: function () {
		if (this.appSetup.isDevelop) {
		}
	},
	methods: {

	},
}
</script>

<style scoped lang="less">

</style>