<template>
	<div>
		<v-alert v-if="showGuide" v-model="showGuide" border="left" :type="type" text colored-border dismissible>
			<div class="title">{{ title }}</div>
			<slot></slot>
		</v-alert>
		<div v-else class="d-flex justify-end">
			<v-icon small title="Visualizza guida" class="mb-3" @click="setShowGuide">mdi-help</v-icon>
		</div>
	</div>
</template>

<script>
/**********************************************************************************************
**** Pannello di help/guida richiudibile ****************************************************************************
***********************************************************************************************
* di base è un semplice alert richiudibile
* è però collegato al meccanismo di configurazione dell'utente così che lo stato aperto o chiuso venga persistito tra le impostazioni del cliente
* quando chiuso di presenta come una piccola icona cliccabile (e ovviamente se cliccato riapre e aggiorna il realtivo flag)

	todo: l'icona dell'alert dovrebbe essere per default un punto di domanda? 
	todo: il colore di default non dovrebbe essere success ma primary
*/

//
export default {
	name: 'DummyInternalPage',
	components: {  },
	props: {
		// id della guida (serve per il salvataggio dello stato aperto/chiuso sulla tabella utente)
		id: {
			type: String,
			required: true
		},		
		// titolo panel
		title: {
			type: String,
			required: true
		},		
		// se true il pannello si presenta per default chiuso anziché aperto
		defaultClosed: {
			type: Boolean,
			required: false,
			default: () => {return false}
		},
		// tipologia del v-alert di base (default = success)
		type: {
			type: String,
			required: false,
			default: () => {return 'success'}
		},
	},
	data: () => {
		return {
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		setup() { return this.$store.getters['setup'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },	
		currentUser() { return this.$store.getters['currentUser'] },
		keyvalues() { return this.$store.getters['keyvalues'] },
		showGuide: {
			get: function() {
				var result = true
				var keyId = 'guide-' + this.id
				// verifica se la keyvalue è presente nello store altrimenti usa il default
				if(this.keyvalues[keyId]) {
					result = this.keyvalues[keyId] === 'true' 
				} else {
					if(this.defaultClosed) result = false
				}
				return result
			},
			set: function(newValue) {
				// salva lo stato del pannello
				var ukv = {
					UserId: this.currentUser.UserName,
					Key: 'guide-' + this.id,
					Value: newValue
				}
				// console.warn('setUserKeyvalue', ukv)
				this.$store.dispatch('setUserKeyvalue', ukv)
			}

		}
	},
	created: function () {
		if (this.appSetup.isDevelop) {
		}
	},
	methods: {
		setShowGuide() {
			this.showGuide = true
		}
	},
}
</script>

<style scoped lang="less">

</style>