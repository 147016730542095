<template>
	<standard-container menu-id="card">
		<!-- header -->
		<template v-slot:header>
			<v-tabs v-model="currentTab" :show-arrows="true" background-color="transparent">
				<v-tab v-if="$isAuthorizedTo('card.new')" key="tabCardNew">
					Nuova card
				</v-tab>
				<v-tab v-if="$isAuthorizedTo('card.verify')" key="tabCardVerify">
					Verifica card
				</v-tab>
				<v-tab v-if="$isAuthorizedTo('card.regolamento')" key="tabCardRegolamento">
					Regolamento
				</v-tab>
			</v-tabs>		
		</template>

		<!-- body slot -->
		<v-tabs-items v-model="currentTab">
			<!-- Nuova card -->
			<v-tab-item v-if="$isAuthorizedTo('card.new')" key="tabCardNew" eager class="ma-1">
				<card-new></card-new>
			</v-tab-item>
			<!-- Verifica card -->
			<v-tab-item v-if="$isAuthorizedTo('card.verify')" key="tabCardVerify" eager class="ma-1">
				<card-verify></card-verify>
			</v-tab-item>
			<!-- Regolamento -->
			<v-tab-item v-if="$isAuthorizedTo('card.regolamento')" key="tabCardRegolamento" class="ma-1">
				<cards-regolamento></cards-regolamento>
			</v-tab-item>
		</v-tabs-items>
	</standard-container>
</template>

<script>
/**********************************************************************************************
**** cards ***********************************************************************************
***********************************************************************************************
* 
*/
import StandardContainer from '@/components/standard-container'
import CardVerify from '@/views/Cards/CardVerify'
import CardNew from '@/views/Cards/CardNew'
import CardsRegolamento from '@/views/Cards/CardsRegolamento'
export default {
	name: 'CardPage',
	components: { StandardContainer, CardVerify, CardNew, CardsRegolamento },
	data: () => {
		return {
			currentTab: -1,
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		setup() { return this.$store.getters['setup'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },	
		currentUser() { return this.$store.getters['currentUser'] },
	},
	mounted() {

	},
	methods: {

	},
}
</script>

<style scoped lang="less">

</style>