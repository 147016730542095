<template>
	<div>
		<guide-panel id="card.verify.verifica" title="VERIFICA CARD">
			In questa sezione, inserendo il numero della Škoda ŠuperCard o il Telaio, è possibile verificare:
			<ul>
				<li>saldo punti del Cliente</li>
				<li>percentuale di sconto accumulata</li>
				<li>promozioni proposte da Škoda, collegate alla Škoda ŠuperCard</li>
			</ul>
		</guide-panel>
		<v-row>
			<v-col>
				<div class="d-block d-md-flex align-stretch">
					<v-text-field v-model="cardCode" focus :error="cardDataInvalid" hint="Codice card = 9 caratteri / Numero telaio = 17 caratteri" counter :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" clearable label="Numero della Card o Telaio" @keyup.enter="verifiyCard" @click:clear="clearCard" />
					<v-btn color="primary" outlined class="ml-0 ml-md-5 mt-md-0" x-large :loading="loading" @click="verifiyCard">CERCA</v-btn>
				</div>
			</v-col>
		</v-row>
		<v-row v-if="cardDataInvalid">
			<v-col><v-alert type="error" text>Numero della card o del telaio non validi</v-alert></v-col>
		</v-row>
		<v-row v-if="cardDataValid && cardData.CardExt.PrivacyRequired">
			<v-col>
				<v-alert type="warning" text colored-border border="left">
					<div class="title">La privacy del cliente deve essere aggiornata</div>
					<p>Chiedere al cliente se vuole rilasciare il consenso privacy per:</p>
					<p class="mb-6">
						<strong>Marketing</strong>: l'autorizzazione ad utilizzare i suoi dati personali per attività di marketing e ricerche di mercato.<br>
						<strong>Profilazione</strong>: l'autorizzazione ad utilizzare i suoi dati personali per attività di profilazione ci permette di informarla sulle iniziative connesse ai suoi interessi.
					</p>
					<v-btn outlined color="warning" @click="openDialogPrivacy">Richiedi consenso</v-btn>
				</v-alert>
			</v-col>
		</v-row>
		<v-row v-if="cardDataValid && cardData.CardExt.Card.UpgradeGold">
			<v-col>
				<v-alert type="warning" text>
					<div class="title">Gold Card</div>
					Il telaio ha diritto ad una &Scaron;koda &Scaron;upercard Gold
				</v-alert>
			</v-col>
		</v-row>
		<v-row v-if="cardDataValid">
			<v-col cols="6">
				<v-card color="tableheader">
					<v-card-title>Dati cliente</v-card-title>
					<v-card-text>
						<v-simple-table dense>
							<template v-slot:default>
								<tbody>
									<tr>
										<th>Cliente</th>
										<td>{{ cardData.CardExt.Cliente.Nome }} {{ cardData.CardExt.Cliente.Cognome }}</td>
									</tr>
									<tr>
										<th>Località</th>
										<td>{{ cardData.CardExt.Cliente.Comune }} ({{ cardData.CardExt.Cliente.Provincia }})</td>
									</tr>
									<tr>
										<th>Codice telaio</th>
										<td>{{ cardData.CardExt.Cliente.CodTelaio }}</td>
									</tr>
									<tr>
										<th>Modello</th>
										<td>{{ cardData.CardExt.Cliente.Modello }} ({{ cardData.CardExt.Cliente.DataImm | shortDate }})</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="6">
				<v-card color="tableheader">
					<v-card-title>Stampa</v-card-title>
					<v-card-text>
						<v-card flat>
							<v-card-text>
								<v-row>
									<v-col md="6">
										<cards-print v-if="cardData.CardExt.VisualizzaStampa && cardDataValid" btn-text="regolamento" :card-id="cardData.CardExt.SuperCard.IdCard" print-type="regolamento*"></cards-print>
									</v-col>
									<v-col md="6" class="text-right">
										<cards-print v-if="cardDataValid" btn-text="allegato C proroga" :card-id="cardData.CardExt.SuperCard.IdCard" print-type="allegato-c-proroga"></cards-print>
									</v-col>
								</v-row>
							</v-card-text>
						</v-card>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<v-row v-if="cardDataValid">
			<v-col cols="12">
				<v-card color="tableheader">
					<v-card-title>Dati card</v-card-title>
					<v-card-text>
						<v-simple-table dense>
							<template v-slot:default>
								<thead>
									<tr>
										<th>ID-Card</th><th>Tipo</th><th>Stato</th><th>Data Attivazione</th><th>Data Annullamento</th><th>Totale Punti</th><th>% Sconto</th><th>Nuova Card</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>{{ cardData.CardExt.SuperCard.IdCard }}</td>
										<td>{{ cardData.CardExt.SuperCard.TipoCard }}</td>
										<td><span :style="'color:' + (cardData.CardExt.SuperCard.Stato == 0 ? '#676767' : (cardData.CardExt.SuperCard.Stato == 1 ? '#219f00' : '#de291e'))">{{ cardData.CardExt.SuperCard.StatoText }}</span></td>
										<td>{{ cardData.CardExt.SuperCard.Attivazione | shortDate }}</td>
										<td>{{ cardData.CardExt.SuperCard.Cessazione | shortDate }}</td>
										<td>{{ cardData.CardExt.TotalePunti }}</td>
										<td>{{ cardData.CardExt.Sconto }}</td>
										<td>{{ cardData.CardExt.SuperCard.NewCard }}</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<v-row v-if="cardDataValid">
			<v-col cols="12">
				<v-card color="tableheader">
					<v-card-title>Promozioni</v-card-title>
					<v-card-text>
						<v-simple-table dense>
							<template v-slot:default>
								<thead>
									<tr>
										<th>Titolo</th><th>Descrizione</th><th>Prezzo/Offerta</th><th>Sconto(%)</th><th>Data Inizio</th><th>Data Fine</th><th>Posizione Lavoro</th><th> </th><th> </th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(item, idx) in cardData.CardExt.PromozioniAttive" :key="idx">
										<th>{{ item.Titolo }}</th>
										<td>{{ item.Descrizione }}</td>
										<td>{{ item.Offerta }}</td>
										<td>{{ item.Sconto }}</td>
										<td>{{ item.DataStart | shortDate }}</td>
										<td>{{ item.DataStop | shortDate }}</td>
										<td>{{ item.PosLavoro }}</td>
										<td>
											<v-icon v-if="item.StatoPromozione==0" color="error">mdi-emoticon-dead</v-icon> 
											<v-icon v-if="item.StatoPromozione==1" color="warning">mdi-emoticon-neutral</v-icon> 
											<v-icon v-if="item.StatoPromozione==2" color="success">mdi-emoticon</v-icon> 
										</td>
										<td>{{ item.Questionario }}</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<v-row v-if="cardDataValid && cardData.CardExt.VisualizzaPassaggi">
			<v-col cols="12">
				<v-card color="tableheader">
					<v-card-title>Storico attività</v-card-title>
					<v-card-text>
						<v-simple-table dense>
							<template v-slot:default>
								<thead>
									<tr>
										<th>Organizzato</th><th>Data</th><th>Descrizione</th><th>TotFattura</th><th>Punti</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(item, idx) in cardData.CardExt.Passaggi" :key="idx">
										<th>{{ item.Organizzato !== "---" ? item.Organizzato : item.CodOrganizzato }}</th>
										<td>{{ item.DataDocumento | shortDate }}</td>
										<td>{{ item.Descrizione }}</td>
										<td>{{ item.ImportoNum | toCurrency }}</td>
										<td>{{ item.SaldoPunti }}</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>		
		<v-row v-if="cardDataValid">
			<v-col cols="6">
				<v-card color="tableheader">
					<v-card-title>Punti - Sconto</v-card-title>
					<v-card-text>
						<v-simple-table dense>
							<template v-slot:default>
								<thead>
									<tr>
										<th>Punti</th><th>Sconto</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(item, idx) in decodifiche.TabellaSconti" :key="idx">
										<td v-if="item.MinimoPunti>0 && item.MassimoPunti>0">{{ item.MinimoPunti }} - {{ item.MassimoPunti }}</td>
										<td v-if="item.MassimoPunti===0"><strong>&gt;</strong> {{ item.MinimoPunti-1 }}</td>
										<th v-if="item.MinimoPunti>0">{{ item.QuotaSconto }} %</th>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>		
		<v-row class="mt-4">
			<v-col>
				<debug-panel label="cardData">
					{{ cardData }}
				</debug-panel>
			</v-col>
		</v-row>
		<!-- <loading :loading="loading" /> -->


		<!-- inizio dialog -->
		<v-dialog v-model="dialogPrivacy" scrollable :fullscreen="$vuetify.breakpoint.name === 'xs'" persistent transition="scale-transition" max-width="1000" @keydown.esc="dialogPrivacy = false">
			<v-card>
				<v-toolbar dense :color="appSetup.appColor" dark>
					<v-btn icon @click="dialogPrivacy = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>Consenso privacy</v-toolbar-title>
					<v-spacer />
					<v-toolbar-items><v-btn text x-large dark :loading="loadingPrivacy" @click="salvaConsensoPrivacy">Salva</v-btn></v-toolbar-items>
				</v-toolbar>
				<v-card-text>
					<div v-if="clienteFullDataValid">
						<v-form ref="form" v-model="validForm" lazy-validation>
							<v-row dense>
								<v-col>
									<v-subheader>Residenza</v-subheader>
									<v-divider></v-divider>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col md="3"><v-select v-model="clienteFullData.Tipovia" :rules="rules.required" :items="decodifiche.TipoVie" label="Tipo via" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" dense required></v-select></v-col>
								<v-col md="6"><v-text-field v-model="clienteFullData.Indirizzo" :rules="rules.required" label="Indirizzo" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" dense required></v-text-field></v-col>
								<v-col md="3"><v-text-field v-model="clienteFullData.Civico" :rules="rules.required" label="Numero civico" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" dense required></v-text-field></v-col>
							</v-row>
							<v-row dense>
								<v-col>
									<v-radio-group v-model="isEstero" row label="Luogo di residenza" @change="variazioneStatoResidenza">
										<v-radio :value="false" label="Italia"></v-radio> 
										<v-radio :value="true" label="Estero"></v-radio> 
									</v-radio-group>
								</v-col>
							</v-row>				
							<v-row v-if="isEstero" dense>
								<v-col md="9">
									<v-autocomplete v-model="clienteFullData.Comune" label="Stato estero" :rules="rules.required" :items="listaStati" item-text="NomeStato" item-value="NomeStato" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" dense></v-autocomplete>
								</v-col>
								<v-col md="3"><v-text-field v-model="clienteFullData.Cap" label="Cap" :rules="rules.required" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" dense required></v-text-field></v-col>
							</v-row>
							<v-row v-else dense>
								<v-col md="3">
									<v-autocomplete v-model="clienteFullData.Provincia" label="Provincia" :rules="rules.required" :items="listaProvincie" item-text="NomeProv" item-value="Sigla" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" dense @change="variazioneProvincia"></v-autocomplete>
								</v-col>
								<v-col md="6">
									<v-autocomplete v-model="clienteFullData.Comune" :rules="rules.required" :disabled="!clienteFullData.Provincia" label="Comune" :items="listaComuni" item-text="NomeComune" item-value="NomeComune" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" dense></v-autocomplete>
								</v-col>
								<v-col md="3"><v-text-field v-model="clienteFullData.Cap" :rules="rules.required" label="Cap" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" dense required></v-text-field></v-col>
							</v-row>
						</v-form>
						<v-row dense>
							<v-col>
								<v-subheader>Contatti</v-subheader>
								<v-divider></v-divider>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col md="6"><v-text-field v-model="clienteFullData.TelFisso" label="Telefono fisso" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" dense></v-text-field></v-col>
							<v-col md="6"><v-text-field v-model="clienteFullData.TelMobile" label="Telefono cellulare" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" dense></v-text-field></v-col>
							<v-col md="12"><v-text-field v-model="clienteFullData.Email" :rules="rules.email" label="Email" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" dense></v-text-field></v-col>
						</v-row>
					
						<v-row>
							<v-col>
								<div class="overline">AUTORIZZAZIONE AL TRATTAMENTO DEI DATI</div>
								<v-divider></v-divider>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="6">
								<div class="body-1">CONSENSO PRIVACY MARKETING</div>
								<v-radio-group v-model="clienteFullData.Privacy2" row class="ma-0">
									<v-radio value="N" label="Non acconsento"></v-radio>
									<v-radio value="S" label="Acconsento"></v-radio>
								</v-radio-group>
							</v-col>
							<v-col cols="6">
								<!-- eslint-disable-next-line vue/no-v-html -->
								<small v-html="decodifiche.TestoPrivacyNewCardChbMarketing"></small>
							</v-col>
							<v-col cols="6">
								<div class="body-1">CONSENSO PRIVACY PROFILAZIONE</div>
								<v-radio-group v-model="clienteFullData.Privacy3" row class="ma-0">
									<v-radio value="N" label="Non acconsento"></v-radio>
									<v-radio value="S" label="Acconsento"></v-radio>
								</v-radio-group>
							</v-col>
							<v-col cols="6">
								<!-- eslint-disable-next-line vue/no-v-html -->
								<small v-html="decodifiche.TestoPrivacyNewCardChbProfilazione"></small>
							</v-col>
						</v-row>	
					</div>			
					<v-row>
						<v-col class="mt-1">
							<errors-container :errors="errors"></errors-container>
						</v-col>
					</v-row>
					<v-row v-if="clienteFullDataValid">
						<v-col>
							<div class="overline">Privacy</div>
							<v-divider></v-divider>
						</v-col>
					</v-row>
					<v-row v-if="clienteFullDataValid">
						<v-col>
							<!-- eslint-disable-next-line vue/no-v-html -->
							<div style="height:200px; overflow:auto" class="mb-3" v-html="decodifiche.TestoPrivacyNewCard"></div>
						</v-col>
					</v-row>

					<debug-panel label="">
						{{ }}
					</debug-panel>
				</v-card-text>
				<v-card-actions class="d-flex justify-space-between">
					<v-btn class="ma-6" large outlined @click="dialogPrivacy = false">Chiudi</v-btn>
					<v-btn class="ma-6" large outlined color="primary" :disabled="!clienteFullDataValid" :loading="loadingPrivacy" @click="salvaConsensoPrivacy">Salva</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>									
		<!-- fine dialog -->
	</div>
</template>

<script>
/**********************************************************************************************
**** CARD VERIFICA ****************************************************************************
***********************************************************************************************
* 
*/
import moment from 'moment'
// import Loading from '@/components/loading'
import DebugPanel from '@/components/debug-panel'
import GuidePanel from '@/components/guide-panel'
import ErrorsContainer from '@/components/errors-container'
import CardsPrint from '@/views/Cards/Print/CardsPrint'
export default {
	name: 'CardVerifyPage',
	components: { DebugPanel,  ErrorsContainer, GuidePanel, CardsPrint },
	data: () => {
		return {
			cardCode: '',
			loading: false,
			loadingPrivacy: false,
			dialogPrivacy: false,
			cardData: null,
			// dati per l'eventuale aggiornamento privacy
			clienteFullData: {},
			clienteFullDataValid: false,
			validForm: true, 
			rules: {
				required: [
					v => !!v || 'Campo obbligatorio',
				],
				email: [
					v => v===null || v=='' || /.+@.+\..+/.test(v) || 'E-mail non valida',
				],
			},
			listaProvincie: [],
			listaComuni: [],
			listaStati: [],
			errors: [], // errori vari 		
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		setup() { return this.$store.getters['setup'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },	
		darkTheme() { return this.$vuetify.theme.dark },
		currentUser() { return this.$store.getters['currentUser'] },
		decodifiche() { return this.$store.getters['decodifiche'] },
		//
		cardDataInvalid() { return !!this.cardCode && !!this.cardData && !this.cardData.IsValid },
		cardDataValid() { return !!this.cardData && !!this.cardData.IsValid },
		//
		// se la provincia corrente è impostata in EE allora il cliente è nato all'estero
		isEstero: {
			get: function() {
				return this.clienteFullData.Provincia == 'EE'
			},
			set: function(newValue) {
				this.clienteFullData.Provincia = newValue ? 'EE' : ''
				this.clienteFullData.Comune = ''
			}
		}	
	},
	watch:{
		// intercetta la chiusura della form di privacy e rilancia la ricerca (cosi da aggiornare lo stato del box della privacy)
		dialogPrivacy(to, from) {
			if(to == false && from == true) {
				this.verifiyCard()
			}
		}
	},	
	created: function () {
		if (this.appSetup.isDevelop) {
			this.cardCode = '24AL11963'
		}
	},
	methods: {
		clearCard() {
			this.cardData = null
		},
		verifiyCard() {
			this.loading = true
			this.cardData = null
			this.$store.dispatch('genericApiPost', {apiUrl: '/card/verify', value: {Code: this.cardCode}}).then(result => {
				this.loading = false
				this.cardData = result
			}).catch(error => {
				console.error('verifiyCard error', error)
			})
		},
		/* metodi per l'aggiornamento della privacy */
		// apre modale e carica dati estesi del cliente 
		openDialogPrivacy() {
			this.caricaCliente()
			this.dialogPrivacy = true
		},
		// carica i dati del cliente ricavando il codice fiscale dai dati della card
		caricaCliente() {
			return new Promise((resolve) => {
				this.loadingPrivacy = true
				this.clienteFullData = {}
				this.clienteFullDataValid = false
				this.errors = []
				this.$store.dispatch('genericApiPost', {apiUrl: '/card/loadCliente', value: {CodiceFiscale: this.cardData.CardExt.Card.CodFiscale}}).then(result => {
					if(result) {
						// console.warn('sovrascrittura cliente:', result)
						this.clienteFullData = result
						this.clienteFullDataValid = true
						this.caricaProvince()
						this.caricaComuni()
					}
				}).catch(error => {
					this.errors.push({text: error, pre: true})
				}).finally(() => {
					this.loadingPrivacy = false
					resolve()
				})
			})
		},
		// carica tutte le province (rispetto allo step precedente qui carica la lista delle attuali)
		caricaProvince() {
			this.loadingPrivacy = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/card/listaProvinceAttuali'}).then(result => {
				this.listaProvincie = result
				if(!result)
					this.errors.push({text: 'Errore: non è stato possibile scaricare l\'elenco delle provincie (attuali)'})
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loadingPrivacy = false
			})
		},
		// carica tutti i comuni della provincia selezionata (usa la data corrente come filtro)
		caricaComuni() {
			if(this.clienteFullData.Provincia) {
				this.loadingPrivacy = true
				this.$store.dispatch('genericApiPost', {apiUrl: '/card/listaComuni', value: {SiglaProvincia: this.clienteFullData.Provincia, DataFiltro: moment().format('YYYY-MM-DD')}}).then(result => {
					this.listaComuni = result
				}).catch(error => {
					this.errors.push({text: error, pre: true})
				}).finally(() => {
					this.loadingPrivacy = false
				})
			}
		},		
		// carica tutti gli stati (usa la data corrente come filtro)
		caricaStati() {
			this.loadingPrivacy = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/card/listaStati', value: {DataFiltro: moment().format('YYYY-MM-DD')}}).then(result => {
				this.listaStati = result
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loadingPrivacy = false
			})
		},		
		// chiamato dalla variazione del radio-button dello stato di residenza, scatena il reload degli stati o dei comuni
		variazioneStatoResidenza() {
			if(this.isEstero) 
				this.caricaStati()
			else 
				this.caricaComuni()
		},
		// alla variazione della provincia ricarica i relativi comuni
		variazioneProvincia() {
			this.caricaComuni()
		},
		salvaConsensoPrivacy() {
			this.errors = []
			// verifica che ci siano tutti i dati necessari e poi procede
			if(this.$refs.form.validate()) {
				// invia tutti i dati al server e speramo bene! :-D
				this.loadingPrivacy = true
				this.$store.dispatch('genericApiPost', {apiUrl: '/card/saveClientePrivacy', value: this.clienteFullData}).then(result => {
					this.dialogPrivacy = false // chiude modale
				}).catch(error => {
					this.errors.push({text: error, pre: true})
				}).finally(() => {
					this.loadingPrivacy = false
				})				
			} else {
				this.errors.push({text: 'É necessario compilare tutti i campi obbligatori'})
			}
		},
	},
}
</script>

<style scoped lang="less">

</style>