<template>
	<div>
		<v-btn :color="btnColor" :outlined="btnOutlined" @click="startPrint">{{ btnText }}</v-btn>
		<v-overlay :value="overlay">
			<v-progress-circular v-if="showProgress"
				indeterminate
				size="64"
			></v-progress-circular>
			<v-chip v-if="showAlert" color="default">chiudere la finestra di stampa per continuare</v-chip>
		</v-overlay>
	</div>
</template>

<script>
/**********************************************************************************************
**** Cards Print ****************************************************************************
***********************************************************************************************
* il sistema di stampa funziona in questo modo:
* il componente renderizza il pulsante stesso necessario ad ad attivare la stampa e va quindi inserito esattamente come un pulsante
* Richiede 2 parametri obbligatori: il tipo di stampa e il codice della card (per ora le stampe sono tutte legate ad una specifica card)
* Gli altri parametri determinano l'aspetto del pulsante
*
* Ricordo che le stampe sono fatte in html, che viene generato server-side tramite dei template liquid
* la stampa vera e propria sfrutta un meccanismo tramite "window.open" derivato dal componente "vue-html-to-paper"
*/
import DebugPanel from '@/components/debug-panel'
import ErrorsContainer from '@/components/errors-container'

//
export default {
	name: 'CardsPrint',
	// eslint-disable-next-line vue/no-unused-components
	components: { DebugPanel,ErrorsContainer },
	props: {
		// codice della card da caricare
		cardId: {
			type: String,
			required: true,
			default: () => {return ''}
		},
		// tipo della stampa da caricare: ('regolamento', 'regolamento-gold', 'regolamento-azienda', 'regolamento-gold-azienda', 'allegato-c-proroga')
		printType: {
			type: String,
			required: true,
			default: () => {return 'regolamento'}
		},
		// testo del pulsante
		btnText: {
			type: String,
			default: () => {return 'stampa'}
		},
		// color del pulsante
		btnColor: {
			type: String,
			default: () => {return 'primary'}
		},		
		// color del pulsante
		btnOutlined: {
			type: Boolean,
			default: () => {return true}
		},		
	},
	data: () => {
		return {
			overlay: false,
			showProgress: true,
			showAlert: false,
			errors: [],
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		setup() { return this.$store.getters['setup'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },	
		currentUser() { return this.$store.getters['currentUser'] },
		decodifiche() { return this.$store.getters['decodifiche'] },
	},
	created: function () {
		if (this.appSetup.isDevelop) {
		}
	},
	methods: {
		// carica la stampa richiesta
		startPrint() {
			this.overlay = true
			this.showProgress = true
			this.showAlert = false			
			this.htmlData = ''
			this.$store.dispatch('genericApiPost', {apiUrl: '/card/print', value: {PrintType: this.printType, CardId: this.cardId}}).then(result => {
				this.printCommand(result.Html).then(() => {
					this.closePrint()
				})
			}).catch(error => {
				console.error('Errore in fase di stampa', error)
				this.closePrint()
			})
		},
		closePrint() {
			this.overlay = false
			this.showProgress = true
			this.showAlert = false				
			this.$emit('input', false)
		},
		printCommand(html) {
			return new Promise((resolve) => {
				// 
				const windowTitle = 'Stampa ' + this.printType
				const url = ''
				const name = '_blank'
				const specs = ['fullscreen=yes','titlebar=yes', 'scrollbars=yes']
				// apre una finestra
				const win = window.open(url, name, specs)
				// carica l'html nella finestra appena creata
				win.document.write(`
					<html>
					<head>
						<title>${windowTitle}</title>
						<style>${this.decodifiche.CssPrint}</style>
					</head>
					<body>
						${html}
					</body>
					</html>	`)
				// dopo un breve timeout, per consentire l'apertura e il rendering della finestra, esegue in rapida sequenza: focus, stampa e chiusura 
				setTimeout(() => {
					this.showProgress = false
					this.showAlert = true	
				}, 999)
				setTimeout(() => {
					win.focus()
					win.print()
					win.document.close()
					win.close()
					resolve()
				}, 1000)
			})
		},
	},
}
</script>

<style scoped lang="less">

</style>