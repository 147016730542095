<template>
	<div>
		<guide-panel id="card.new.new" title="NUOVA CARD">
			In questa sezione è possibile registrare le nuove card
		</guide-panel>		
		<v-stepper v-model="stepIndex" alt-labels>
			<v-stepper-header>
				<v-stepper-step :complete="stepIndex > 1" step="1">Tipo cliente</v-stepper-step><v-divider></v-divider>
				<v-stepper-step :complete="stepIndex > 2" step="2">Dati card</v-stepper-step><v-divider></v-divider>
				<v-stepper-step :complete="stepIndex > 3" step="3">Dati telaio</v-stepper-step><v-divider></v-divider>
				<v-stepper-step :complete="stepIndex > 4" step="4">Dati cliente</v-stepper-step><v-divider></v-divider>
				<v-stepper-step :complete="stepIndex > 5" step="5">Recapiti</v-stepper-step><v-divider></v-divider>
				<v-stepper-step :complete="stepIndex > 6" step="6">Riepilogo</v-stepper-step><v-divider></v-divider>
				<v-stepper-step :complete="stepIndex > 7" step="7">Stampa</v-stepper-step>
			</v-stepper-header>

			<v-stepper-items>
				<!-- [1] tipologia cliente -->
				<v-stepper-content step="1">
					<stepper-container title="Tipologia cliente" :errors="erroriTipoCliente" subtitle="Seleziona la tipologia privato/azienda dell'utente di riferimento" :loading="loading" @forward="impostaTipoCliente">
						<v-radio-group v-model="cardData.TipoCliente">
							<v-radio label="Privato"></v-radio> 
							<v-radio label="Azienda"></v-radio> 
						</v-radio-group>
					</stepper-container>
				</v-stepper-content>
				<!-- [2] dati card -->
				<v-stepper-content step="2">
					<stepper-container title="Dati card" :errors="erroriDatiCard" :loading="loading" @forward="verificaDatiCard">
						<v-row>
							<v-col>
								<v-text-field ref="numeroCard" v-model="cardData.Numero" label="Numero della card" hint="(il numero della card è presente sul retro del kit 'SuperCard')" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :rules="[() => !!cardData.Numero || 'Inserire il numero della card!', () => cardData.Numero.length===9 || 'Il numero della card è formato a 9 caratteri']" required></v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col md="6">
								<div>Data di registrazione</div>
								<v-date-picker v-model="cardData.DataRegistrazione" full-width :max="maxRegistrationDate" :min="minRegistrationDate"></v-date-picker>
							</v-col>
						</v-row>
					</stepper-container>
				</v-stepper-content>
				<!-- [3] - registrazione telaio -->
				<v-stepper-content step="3">
					<step3 v-model="cardData" :visible="stepIndex == 3" @forward="stepIndex = 4"></step3>
				</v-stepper-content>
				<!-- [4] - anagrafica cliente -->
				<v-stepper-content step="4">
					<step4 v-model="cardData" :visible="stepIndex == 4" @forward="stepIndex = 5"></step4>
				</v-stepper-content>				
				<!-- [5] - recapiti -->
				<v-stepper-content step="5">
					<step5 v-model="cardData" :visible="stepIndex == 5" @forward="stepIndex = 6"></step5>
				</v-stepper-content>				
				<!-- [6] - riepilogo e flag privacy -->
				<v-stepper-content step="6">
					<riepilogo v-model="cardData" :visible="stepIndex == 6" @forward="stepIndex = 7"></riepilogo>
				</v-stepper-content>				
				<!-- [7] - fine e stampa -->
				<v-stepper-content step="7">
					<stepper-container title="Stampa" show-finish-button :show-forward-button="false" button-finish-text="Nuova card" @finish="nuovaCard">
						<v-alert type="success" border="left" colored-border  >
							<div class="title">Inserimento dati completato</div>
							<br>
							<div class="title">Procedere con la stampa in <u>duplice copia</u> del regolamento da far firmare al cliente.</div>
							<br>
							<cards-print btn-text="APRI IL REGOLAMENTO COMPILATO PER LA STAMPA" :card-id="cardData.Numero" print-type="regolamento*"></cards-print>
							<br>
						</v-alert>
					</stepper-container>
				</v-stepper-content>				
			</v-stepper-items>
		</v-stepper>

		<v-row class="mt-4">
			<v-col>
				<debug-panel label="cardData">
					{{ cardData }}
				</debug-panel>				
			</v-col>
			<v-col>
				<debug-panel label="currentUser">
					{{ currentUser }}
				</debug-panel>				
			</v-col>
		</v-row>
	</div>
</template>

<script>
/**********************************************************************************************
**** CARD NEW ****************************************************************************
***********************************************************************************************
* 
*/
import moment from 'moment'
import DebugPanel from '@/components/debug-panel'
import GuidePanel from '@/components/guide-panel'
import StepperContainer from '@/components/stepper-container'
import Step3 from '@/views/Cards/card-new-steps/Step3'
import Step4 from '@/views/Cards/card-new-steps/Step4'
import Step5 from '@/views/Cards/card-new-steps/Step5'
import Riepilogo from '@/views/Cards/card-new-steps/Riepilogo'
import CardsPrint from '@/views/Cards/Print/CardsPrint'
export default {
	name: 'CardNewPage',
	components: { DebugPanel, GuidePanel, StepperContainer, Step3, Step4, Step5, Riepilogo, CardsPrint },
	data: () => {
		return {
			stepIndex: 1,
			loading: false,
			erroriTipoCliente: [],
			cardData: {
				CodiceOrganizzato: '',
				TipoCliente: null, // 0 privato, 1 azienda
				Numero: '',
				DataRegistrazione: moment().format('YYYY-MM-DD') , // new Date().toISOString().substr(0, 10),
				MigrazionePuntiOldCard: '', // eventuale id della "vecchia" card dalla quale si migrano i punti 
			},
			erroriDatiCard: [],
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		setup() { return this.$store.getters['setup'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },	
		darkTheme() { return this.$vuetify.theme.dark },
		currentUser() { return this.$store.getters['currentUser'] },
		// massima data possibile di registrazione (vincola il calendario)
		maxRegistrationDate() {
			return moment().format('YYYY-MM-DD') //new Date().toISOString().substr(0, 10)
		},
		// minima data possibile di registrazione (vincola il calendario)
		minRegistrationDate() {
			return moment().subtract(10, 'days').format('YYYY-MM-DD') //new Date().toISOString().substr(0, 10)
		},
	},
	mounted() {
		
	},
	created: function () {
		this.cardData.CodiceOrganizzato = this.currentUser.UserName
		if (this.appSetup.isDevelop && this.viewDebugElements) {
			this.cardData.TipoCliente = 0
			this.cardData.Numero = '00AA00145'
		}
	},		
	methods: {
		// step 1
		impostaTipoCliente() {
			if(this.cardData.TipoCliente != null)
				this.stepIndex = 2
			else
				this.erroriTipoCliente.push({text: 'Impostare la tipologia del cliente'})
		},
		/*   */
		// step 2, verifica i dati della card
		verificaDatiCard() {
			this.erroriDatiCard = []
			this.$refs['numeroCard'].validate(true)
			if(this.cardData.Numero.length===9) {
				this.loading = true
				this.$store.dispatch('genericApiPost', {apiUrl: '/card/status', value: {Code: this.cardData.Numero}}).then(result => {
					if(result === 0) // ok
						this.stepIndex = 3
					else if(result === 1) 
						this.erroriDatiCard.push({text: 'La Card risulta già associata ad un telaio'})
					else if(result === 2) 
						this.erroriDatiCard.push({text: 'La Card risulta annullata'})
					else if(result === -1) 
						this.erroriDatiCard.push({text: 'Il numero della Card inserito è inesistente!'})
					else 
						this.erroriDatiCard.push({text: 'Si è verificato un errore'})
				}).catch(error => {
					this.erroriDatiCard.push({text: error, pre: true})
				}).finally(() => {
					this.loading = false
				})
			} else {
				this.erroriDatiCard.push({text: 'Il numero inserito non è valido'})
			}
		},
		// ricomincia
		nuovaCard() {
			this.$router.push({name:'home'}).then(() => {
				this.$router.push({name:'card'})
			})
			/*
			this.stepIndex = 1
			this.cardData = {
				CodiceOrganizzato: '',
				TipoCliente: null,
				Numero: '',
				DataRegistrazione: moment().format('YYYY-MM-DD') , 
			}
			*/
		},
	},
}
</script>

<style scoped lang="less">

</style>