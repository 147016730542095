<template>
	<div v-if="visible">
		<!-- anagrafica privati -->
		<stepper-container v-if="!migrazione && value.TipoCliente==0" :loading="loading" :errors="sstep10errors" title="Dati anagrafici" subtitle="" @forward="salvaAnagraficaPrivato">
			<v-row>
				<v-col md="6"><v-text-field v-model="cliente.Nome" label="Nome" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" required></v-text-field></v-col>
				<v-col md="6"><v-text-field v-model="cliente.Cognome" label="Cognome" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" required></v-text-field></v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-radio-group v-model="cliente.Sesso" row>
						<v-radio value="M" label="Maschio"></v-radio> 
						<v-radio value="F" label="Femmina"></v-radio> 
					</v-radio-group>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<div>Data di nascita <small>(clicca sull'anno per iniziare la compilazione)</small></div>
					<v-date-picker v-model="cliente.DataNascita" full-width :max="maxDataNascita" :min="minDataNascita" @change="variazioneData"></v-date-picker>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-radio-group v-model="isEstero" :disabled="!cliente.DataNascita" row label="Luogo di nascita" @change="variazioneStatoNascita">
						<v-radio :value="false" label="Italia"></v-radio> 
						<v-radio :value="true" label="Estero"></v-radio> 
					</v-radio-group>
				</v-col>
			</v-row>
			<v-row v-if="isEstero">
				<v-col md="6">
					<v-autocomplete v-model="cliente.ComuneNas" :disabled="!cliente.DataNascita" label="Stato estero" :items="listaStati" item-text="NomeStato" item-value="NomeStato" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" @change="calcolaCodiceFiscale"></v-autocomplete>
				</v-col>
			</v-row>
			<v-row v-else>
				<v-col md="6">
					<v-autocomplete v-model="cliente.ProvinciaNas" :disabled="!cliente.DataNascita" label="Provincia nascita" :items="listaProvincie" item-text="NomeProv" item-value="Sigla" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" @change="variazioneProvincia"></v-autocomplete>
				</v-col>
				<v-col md="6">
					<v-autocomplete v-model="cliente.ComuneNas" :disabled="!cliente.DataNascita || !cliente.ProvinciaNas" label="Comune nascita" :items="listaComuni" item-text="NomeComune" item-value="NomeComune" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" @change="calcolaCodiceFiscale"></v-autocomplete>
				</v-col>
			</v-row>
			<v-row>
				<v-col md="6">
					<v-text-field v-model="cliente.Codicefiscale" label="Codice fiscale" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" required></v-text-field>
				</v-col>
				<v-col md="6">					
					<v-alert v-if="cliente.Codicefiscale" type="warning" outlined dense>Prima di procedere è importante verificare che il codice fiscale sia stato calcolato correttamente.<br>Altrimenti è possibile inserirlo manualmente.</v-alert>
				</v-col>
			</v-row>
		</stepper-container>

		<!-- anagrafica aziende -->
		<stepper-container v-if="!migrazione && value.TipoCliente==1" :loading="loading" :errors="sstep20errors" title="Dati anagrafici azienda" subtitle="" @forward="salvaAnagraficaAzienda">
			<v-row>
				<v-col><v-text-field v-model="cliente.RagSoc" label="Ragione sociale" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" required></v-text-field></v-col>
			</v-row>
			<v-row>
				<v-col><v-text-field v-model="cliente.Codicefiscale" label="Partita iva" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" required></v-text-field></v-col>
			</v-row>
		</stepper-container>

		<!-- anagrafica aziende -->
		<stepper-container v-if="migrazione" :loading="loading" :errors="sstep30errors" title="Migrazione punti" subtitle="" @forward="procediDopoMigrazione">
			<v-row>
				<v-col>
					<v-switch v-model="eseguiMigrazione" label="Attiva questo flag per effettuare la migrazione dei punti da un precedente telaio oppure prosegui creando una nuova card"></v-switch>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="6">
					<v-select v-model="telaioMigrazione" :disabled="!eseguiMigrazione" return-object :items="telaiMigrazione" item-text="CodTelaio" label="Telai" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense"></v-select>
				</v-col>
				<v-col cols="6">
					<table v-if="eseguiMigrazione" class="table-telaio">
						<tr><td colspan="2" class="subtitle-2">DATI TELAIO</td></tr> 
						<tr><td class="caption">Codice Telaio</td><td class="subtitle-2">{{ telaioMigrazione.CodTelaio }}</td></tr>
						<tr><td class="caption">Targa</td><td class="subtitle-2">{{ telaioMigrazione.Targa }}</td></tr>
						<tr><td class="caption">Data di immatricolazione</td><td class="subtitle-2">{{ telaioMigrazione.DataImmatricolazione }}</td></tr>
						<tr><td class="caption">Modello</td><td class="subtitle-2">{{ telaioMigrazione.Modello }}</td></tr>
						<tr><td class="caption">ID-Card</td><td class="subtitle-2">{{ telaioMigrazione.CardAssegnata }}</td></tr>
						<tr><td class="caption">Punti accumulati</td><td class="subtitle-2">{{ telaioMigrazione.Punti }}</td></tr>
					</table>
				</v-col>
			</v-row>	
			<v-row>
				<v-col>
					<div v-if="!eseguiMigrazione">
						<v-alert type="info" outlined>Procedendo non verranno migrati punti e verrà creata una nuova card.</v-alert>
					</div>
					<div v-if="eseguiMigrazione">
						<v-alert v-if="!telaioMigrazione.CodTelaio" type="info" outlined>Nessun telaio selezionato, procedendo non verranno migrati punti e verrà creata una nuova card.</v-alert>
						<v-alert v-else type="warning" outlined>Procedendo verranno migrati {{ telaioMigrazione.Punti }} punti dalla card "{{ telaioMigrazione.CardAssegnata }}".</v-alert>
					</div>
				</v-col>
			</v-row>							
		</stepper-container>


		<v-row>
			<v-col>
				<debug-panel label="cliente" class="mt-6">
					{{ cliente }}
				</debug-panel>	
			</v-col>
		</v-row>
	</div>
</template>

<script>
/**********************************************************************************************
**** STEP 4 - registrazione dati anagrafici ****************************************************
***********************************************************************************************
* questo step è particolarmente complesso ed è in pratica formato da alcuni sub-step, per questo motivo è stato componentizzato a parte
*
* sulla carta sembra è un semplice caricamento di anagrafica (divisa tra provati e aziende) 
* ma nasconde alcuni dettagli complessi sulle varie verifiche e sulla migrazione dei punti in caso di codice fiscale già presente
* 
* 

*/
import moment from 'moment'
import DebugPanel from '@/components/debug-panel'
import StepperContainer from '@/components/stepper-container'
import CodiceFiscale from 'codice-fiscale-js'
//
export default {
	name: 'CardNewStep4',
	components: { StepperContainer, DebugPanel },
	props: {
		// questo è il v-model, ed equivale al solito oggetto card 
		value: {
			type: Object,
			required: true,
			default: () => {return {}}
		},
		// proprietà che indica quando questo step è effettivamente visibile 
		// è inoltre sotto watch per determinare il momento in cui diventa visibile e avviare quindi alcune operazioni
		visible: {
			type: Boolean,
			required: true,
			default: () => {return false}
		},
	},
	data: () => {
		return {
			loading: false, // loading globale
			// cardData: {}, // dati della card (v-model del componente)
			cliente: {
				TipoCliente: 0, //  0 privato, 1 azienda (viene dal primo step e quindi da cardData)
				RagSoc: '',
				Sesso: '', // M/F 
				Nome: '',
				Cognome: '',
				Codicefiscale : '',
				DataNascita: null,
				CodCatastaleNascita: '',
				ComuneNas: '',
				ProvinciaNas : '',
				// dati di recapito, serviranno allo step successivo
				Tipovia: '',
				Indirizzo: '',
				Civico: '',
				Comune: '',
				Provincia: '',
				Cap: '',
				TelFisso: '',
				TelMobile: '',
				Email: '',
				Smartphone: '',
				PhoneOs: '',
				Privacy: '',
				Privacy2: '',
				Privacy3: '',
				Privacy4: '',
			},
			// sub-step 10 - anagrafica privati
			sstep10errors: [], // errori vari 
			listaProvincie: [],
			listaComuni: [],
			listaStati: [],

			// sub-step 20 - anagrafica aziende
			sstep20errors: [], // errori vari 

			// sub-step 30 - migrazione punti
			migrazione: false, // determina la visualizzazione, quando necessario, del substep di migrazione
			telaiMigrazione: [], // se la migrazione è consentita questo è l'elenco dei telai tra cui scegliere
			eseguiMigrazione: false, // indica se l'utente ha richiesto o meno la migrazione dei punti
			telaioMigrazione: {}, // se l'utente ha richiesto la migrazione questo è il telaio da cui effettuarla 
			sstep30errors: [], // errori vari 
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		setup() { return this.$store.getters['setup'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },	
		currentUser() { return this.$store.getters['currentUser'] },
		// massima data possibile di nascita (vincola il calendario) viene calcolata da oggi -18 anni, vale a dire che il cliente deve essere maggiorenne
		maxDataNascita() {
			return moment().subtract(18, 'years').format('YYYY-MM-DD') 
		},
		// minima data possibile di nascita (vincola il calendario), 
		minDataNascita() {
			return moment().subtract(120, 'years').format('YYYY-MM-DD') 
		},
		// se la provincia corrente è impostata in EE allora il cliente è nato all'estero
		isEstero: {
			get: function() {
				return this.cliente.ProvinciaNas == 'EE'
			},
			set: function(newValue) {
				this.cliente.ProvinciaNas = newValue ? 'EE' : ''
				this.cliente.ComuneNas = ''
			}
		}
	},
	watch:{
		// quando la proprietà visible passa a true significa che lo step è stato visualizzato
		visible(to, from) {
			if(to!==from && to) {
				this.caricaProvince()
				if (this.appSetup.isDevelop && this.viewDebugElements) {
					this.cliente.Nome = 'Pippo' 
					this.cliente.Cognome = 'Pluto' 
					this.cliente.Sesso = 'M' 
					this.cliente.DataNascita = '1999-12-31' 
					this.cliente.ProvinciaNas = 'RM' 
					this.cliente.ComuneNas = 'ROMA' 
					this.caricaComuni()
					this.calcolaCodiceFiscale()
				}
			}
		}
	},		
	mounted: function () {
		//
	},	
	methods: {
		// carica tutte le province
		caricaProvince() {
			this.loading = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/card/listaProvince'}).then(result => {
				this.listaProvincie = result
				if(!result)
					this.sstep10errors.push({text: 'Errore: non è stato possibile scaricare l\'elenco delle provincie'})
			}).catch(error => {
				this.sstep10errors.push({text: error, pre: true})
			}).finally(() => {
				this.loading = false
			})
		},
		variazioneData() {
			if(this.isEstero)
				this.caricaStati()
			else
				this.caricaComuni()
		},
		// chiamato dalla variazione del radio-button dello stato di nascita, scatena il reload degli stati o dei comuni
		variazioneStatoNascita() {
			if(this.isEstero) {
				// this.cliente.ProvinciaNas = ''
				// this.cliente.ComuneNas = ''
				this.caricaStati()
			}
			else {
				// this.cliente.StatoEstero = ''
				this.caricaComuni()
			}
		},
		// alla variazione della provincia ricarica i relativi comuni
		variazioneProvincia() {
			this.caricaComuni()
		},
		// carica tutti i comuni della provincia selezionata (filtra inoltre per data di nascita per consentire la visualizzazione anche di comuni cessati)
		caricaComuni() {
			if(this.cliente.ProvinciaNas) {
				this.loading = true
				this.$store.dispatch('genericApiPost', {apiUrl: '/card/listaComuni', value: {SiglaProvincia: this.cliente.ProvinciaNas, DataFiltro:this.cliente.DataNascita}}).then(result => {
					this.listaComuni = result
				}).catch(error => {
					this.sstep10errors.push({text: error, pre: true})
				}).finally(() => {
					this.loading = false
				})
			}
		},
		// carica tutti gli stati (filtra inoltre per data di nascita per consentire la visualizzazione anche degli stati non più esistenti)
		caricaStati() {
			this.loading = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/card/listaStati', value: {DataFiltro:this.cliente.DataNascita}}).then(result => {
				this.listaStati = result
			}).catch(error => {
				this.sstep10errors.push({text: error, pre: true})
			}).finally(() => {
				this.loading = false
			})
		},
		// usa il componente "https://github.com/lucavandro/CodiceFiscaleJS"
		calcolaCodiceFiscale() {
			var cf = new CodiceFiscale({
				name: this.cliente.Nome,
				surname: this.cliente.Cognome,
				gender: this.cliente.Sesso,
				day: moment(this.cliente.DataNascita).date(),
				month: moment(this.cliente.DataNascita).month() + 1,
				year: moment(this.cliente.DataNascita).year(),
				birthplace: this.cliente.ComuneNas, 
				birthplaceProvincia: this.isEstero ? 'EE' :  this.cliente.ProvinciaNas
			})
			//console.warn('Codicefiscale', cf)
			this.cliente.Codicefiscale = cf ? cf.cf : ''
		},
		// salva i dati anagrafici per i privati e passa allo step successivo
		salvaAnagraficaPrivato() {
			this.cliente.TipoCliente = this.value.TipoCliente
			this.sstep10errors = []
			// verifica che ci siano tutti i dati necessari e poi procede
			let valid = true
			if(!this.cliente || !this.cliente.Nome || !this.cliente.Cognome || !this.cliente.Sesso || !this.cliente.Codicefiscale || !this.cliente.DataNascita || !this.cliente.ProvinciaNas || !this.cliente.ComuneNas) {
				valid = false
				this.sstep10errors.push({text: 'É necessario compilare tutti i campi'})
			} else if (this.cliente.Codicefiscale.length != 16) {
				valid = false
				this.sstep10errors.push({text: 'Il codice fiscale deve essere di 16 caratteri.'})
			}
			if(valid) {
				this.value.Cliente = this.cliente
				this.loading = true
				this.$store.dispatch('genericApiPost', {apiUrl: '/card/verificaMigrazione', value: {Codicefiscale:this.cliente.Codicefiscale, NumeroCard: this.value.Numero}}).then(result => {
					if(result.AbilitaMigrazione) {
						// è necessario visualizzare il substep di migrazione
						this.migrazione = true
						this.telaiMigrazione = result.Telai
					} else {
						// non serve il substep di migrazione, è possibile proseguire
						this.$emit('forward') // scatena l'evento di forward finale (che  effettua quindi il passaggio allo step successivo)
					}
				}).catch(error => {
					this.sstep10errors.push({text: error, pre: true})
				}).finally(() => {
					this.loading = false
				})
			}
		},
		// salva i dati anagrafici per le aziende e passa allo step successivo
		salvaAnagraficaAzienda() {
			this.cliente.TipoCliente = this.value.TipoCliente
			this.cliente.Sesso = 'D' // identifica le aziende (nel db manca un campo apposito)
			this.sstep20errors = []
			// verifica che ci siano tutti i dati necessari e poi procede
			let valid = true
			if(!this.cliente || !this.cliente.RagSoc || !this.cliente.Codicefiscale) {
				valid = false
				this.sstep20errors.push({text: 'É necessario compilare tutti i campi'})
			}else if (this.cliente.Codicefiscale.length != 11) {
				valid = false
				this.sstep20errors.push({text: 'La partita iva deve essere formata da 11 numeri'})
			}
			if(valid) {
				this.value.Cliente = this.cliente
				this.loading = true
				this.$store.dispatch('genericApiPost', {apiUrl: '/card/verificaMigrazione', value: {Codicefiscale:this.cliente.Codicefiscale, NumeroCard: this.value.Numero}}).then(result => {
					if(result.AbilitaMigrazione) {
						// è necessario visualizzare il substep di migrazione
						this.migrazione = true
						this.telaiMigrazione = result.Telai
					} else {
						// non serve il substep di migrazione, è possibile proseguire
						this.$emit('forward') // scatena l'evento di forward finale (che  effettua quindi il passaggio allo step successivo)
					}
				}).catch(error => {
					this.sstep10errors.push({text: error, pre: true})
				}).finally(() => {
					this.loading = false
				})				
			}
		},
		//
		// salva e procede allo step successivo in caso di migrazione punti
		// ricordo che la migrazione punti si attiva quando il codice fiscale dell'utente è già collegato ad uno o più precedenti telaio
		// e in tal caso si da la possibilità di scegliere se migrare o meno i punti da uno dei telai
		// 
		procediDopoMigrazione() {
			if(this.eseguiMigrazione && this.telaioMigrazione.CodTelaio) {
				this.value.MigrazionePuntiOldCard = this.telaioMigrazione.CardAssegnata
			}
			this.$emit('forward') // scatena l'evento di forward finale (che  effettua quindi il passaggio allo step successivo)
		},
	},
}
</script>

<style scoped lang="less">
	.table-telaio {
		td {
			padding-left: 5px;
		}
	}
</style>