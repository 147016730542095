var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('guide-panel',{attrs:{"id":"card.regolamento.regolamento","title":"REGOLAMENTO SUPERCARD"}},[_vm._v(" Regolamenti e allegati. ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"loading":_vm.loading,"items":_vm.files,"headers":_vm.headers,"sort-by":['Nome'],"sort-desc":[false],"item-key":"Id","show-expand":"","expanded":_vm.expanded,"single-expand":true,"disable-pagination":"","disable-sort":"","hide-default-footer":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.Caricamento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("shortDate")(item.Caricamento))+" ")]}},{key:"item.Id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.viewFile(item)}}},[_c('v-icon',{attrs:{"title":"Apri documento","color":"primary"}},[_vm._v("mdi-magnify")]),_vm._v(" visualizza")],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"expanded"},[_c('p',[_vm._v(_vm._s(item.Descrizione))])])])]}}],null,true)})],1)],1),_c('errors-container',{attrs:{"errors":_vm.errors}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }