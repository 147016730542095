<template>
	<v-text-field v-model="valore" readonly :label="label" :dense="tce.dense"></v-text-field>
</template>

<script>
/**********************************************************************************************
**** CAMPO DI TESTO READONLY ****************************************************************************
***********************************************************************************************
* componente di comodo per renderizzare rapidamente un v-text-field readonly personalizzato
* implementa inoltre una proprietà per formattare le date (showdate)
*/
import moment from 'moment'
//
export default {
	name: 'ReadonlyText',
	components: {  },
	props: {
		// v-model (anche se comunque il componete è readonly)
		value: {
			type: [String, null],
			required: true,
			default: () => {return ''}
		},
		label: {
			type: String,
			required: true,
			default: () => {return ''}
		},
		showdate: {
			type: Boolean,
			required: false,
			default: () => {return false}
		},
	},
	data: () => {
		return {
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		setup() { return this.$store.getters['setup'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },	
		// valore da visualizzare opportunamente processato
		valore() {
			if(this.showdate) {
				return moment(this.value).format('DD MMMM YYYY')
			}else {
				return this.value
			}
		}
	},
}
</script>

<style scoped lang="css">
	/* nasconde la sottolineatura del campo standard */
	>>> .v-input__slot::before {
	border-style: none !important;
	}
</style>